import { getField, updateField } from "vuex-map-fields";

export default {
    namespaced: true,
    state: {

    },
    getters: {
        getField, //vuex-map-fields
    },
    mutations: {
        updateField, //vuex-map-fields
    },
    actions: {
        showError({ commit, state, dispatch }, payload) {
            let text;
            let error = payload.error;
            if (error.response) {
                if (error.response.status != 200 && error.response.status != 422) {
                    let errText =
                        error.response && error.response.data && error.response.data.message
                            ? error.response.data.message
                            : false;
                    text =
                        errText || error.response.status + " " + error.response.statusText;
                } else {
                    text = Object.entries(error.response.data.errors)[0][1][0];
                }
            } else if (error.message) {
                text = error.message;
            } else {
                text = error;
            }


            this._vm.$dialog.notify.error(text || "Unknown error", {
                position: 'bottom-right',
                timeout: 3000
            })

        },
    },
};
