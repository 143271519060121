<template>
  <v-dialog
    v-model="show"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    scrollable
  >
    <v-card>
      <v-toolbar dark color="primary" class="flex-grow-0">
        <v-toolbar-title>{{ $t("common.book_now") }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-3">
        <v-container>
          <v-row no-gutters>
            <v-col cols="12">
              <v-stepper v-model="currentStep" v-if="!isSuccessSent" vertical>
                <v-stepper-step :complete="currentStep > 1" step="1">
                  {{ $t("booking.choosing") }}
                  <small
                    v-if="
                      newBooking.shop &&
                      newBooking.shop.shop_id &&
                      currentStep > 1
                    "
                    >{{ newBooking.shop.legal_name }}
                    {{
                      newBooking.shop.phone !== "" && newBooking.shop.phone
                        ? `(${newBooking.shop.phone})`
                        : ""
                    }}</small
                  >
                </v-stepper-step>

                <v-stepper-content step="1">
                  <v-list>
                    <v-list-item-group
                      color="primary"
                      @change="(shop) => (newBooking.shop = shop)"
                    >
                      <v-list-item
                        v-for="item in shops"
                        :key="item.shop_id"
                        :value="item"
                      >
                        <v-list-item-icon>
                          <v-icon>mdi-car-wrench</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="item.legal_name"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                  <v-btn
                    color="primary"
                    @click="currentStep = 2"
                    :disabled="!newBooking.shop || !newBooking.shop.shop_id"
                  >
                    {{ $t("common.continue") }}
                  </v-btn>
                </v-stepper-content>

                <v-stepper-step :complete="currentStep > 2" step="2">
                  {{ $t("booking.choosing_car") }}
                  <small
                    v-if="
                      newBooking.car && newBooking.car.id && currentStep > 2
                    "
                  >
                    {{ newBooking.car.brand }}
                    {{ newBooking.car.family }}
                    {{ newBooking.car.model }}
                  </small>
                </v-stepper-step>

                <v-stepper-content step="2">
                  <v-list>
                    <v-list-item-group
                      color="primary"
                      @change="(car) => (newBooking.car = car.car)"
                    >
                      <v-list-item
                        v-for="item in cars"
                        :key="item.shop_id"
                        :value="item"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ item.car.brand }}
                            {{ item.car.family }}
                            {{ item.car.model }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                  <v-btn
                    color="primary"
                    @click="currentStep = 3"
                    :disabled="!newBooking.car || !newBooking.car.id"
                  >
                    {{ $t("common.continue") }}
                  </v-btn>
                  <v-btn text @click="currentStep = 1">
                    {{ $t("common.cancel") }}
                  </v-btn>
                </v-stepper-content>

                <v-stepper-step :complete="currentStep > 3" step="3">
                  {{ $t("booking.choosing_repairtimes") }}
                </v-stepper-step>

                <v-stepper-content step="3">
                  <v-card elevation="0">
                    <choose-operations
                      @changeOperations="chooseOperations"
                      :shop_id="newBooking.shop && newBooking.shop.shop_id"
                    />
                  </v-card>
                  <v-btn
                    color="primary"
                    @click="currentStep = 4"
                    :disabled="
                      (newBooking.comment === null ||
                        newBooking.comment === '') &&
                      (newBooking.operations === null ||
                        newBooking.operations.length === 0)
                    "
                  >
                    {{ $t("common.continue") }}
                  </v-btn>
                  <v-btn text @click="currentStep = 2">
                    {{ $t("common.previous") }}
                  </v-btn>
                </v-stepper-content>

                <v-stepper-step :complete="currentStep > 4" step="4">
                  {{ $t("booking.choosing_date") }}
                  <small v-if="newBooking.desiredDate">
                    {{ newBooking.desiredDate | moment("DD.MM.YYYY HH:mm") }}
                  </small>
                </v-stepper-step>
                <v-stepper-content step="4">
                  <v-card class="mb-3 pt-1" elevation="0">
                    <v-datetime-picker
                      class=""
                      :textFieldProps="{
                        outlined: true,
                        dense: true,
                        'hide-details': true,
                      }"
                      :label="$t('booking.select_your_desired_date_and_time')"
                      v-model="newBooking.desiredDate"
                      dateFormat="dd.MM.yyyy"
                      timeFormat="HH:mm"
                      :timePickerProps="timePickerProps"
                      :datePickerProps="datePickerProps"
                      locale="RU-ru"
                    >
                      <template slot="dateIcon">
                        <v-icon>mdi-calendar</v-icon>
                      </template>
                      <template slot="timeIcon">
                        <v-icon>mdi-clock</v-icon>
                      </template>
                    </v-datetime-picker>
                  </v-card>
                  <v-btn
                    color="primary"
                    @click="currentStep = 5"
                    :disabled="!newBooking.desiredDate"
                  >
                    {{ $t("common.continue") }}
                  </v-btn>
                  <v-btn text @click="currentStep = 3"
                    >{{ $t("common.previous") }}
                  </v-btn>
                </v-stepper-content>

                <v-stepper-step step="5">
                  {{ $t("booking.confirmation") }}
                </v-stepper-step>
                <v-stepper-content step="5">
                  <v-card class="mb-3 pt-1" elevation="0">
                    <h3 class="mb-0 pb-0">
                      {{ newBooking.desiredDate | moment("DD.MM.YYYY HH:mm") }}
                    </h3>
                    <small class="text-caption"
                      >{{ $t("booking.date_hint") }}
                    </small>
                    <br />
                    <br />
                    <h3>{{ newBooking.shop && newBooking.shop.legal_name }}</h3>
                    <strong>{{
                      newBooking.shop && newBooking.shop.fact_address
                    }}</strong>
                    <strong
                      v-if="
                        newBooking.shop &&
                        newBooking.shop.phone &&
                        newBooking.shop.phone !== ''
                      "
                      ><br />{{
                        newBooking.shop && newBooking.shop.phone
                      }}</strong
                    >
                    <br /><br />
                    <h3>
                      <strong>{{
                        newBooking && newBooking.car && newBooking.car.brand
                      }}</strong>
                      <strong>{{
                        newBooking && newBooking.car && newBooking.car.family
                      }}</strong>
                      <strong>{{
                        newBooking && newBooking.car && newBooking.car.model
                      }}</strong>
                    </h3>
                    <br />
                  </v-card>
                  <v-btn
                    color="primary"
                    @click="book"
                    :disabled="isLoading"
                    :loading="isLoading"
                  >
                    {{ $t("booking.book") }}
                  </v-btn>
                  <v-btn text @click="currentStep = 4"
                    >{{ $t("common.previous") }}
                  </v-btn>
                </v-stepper-content>
              </v-stepper>
              <v-alert outlined color="success" v-if="isSuccessSent">
                {{ $t("booking.success_sent") }}
              </v-alert>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import StatusDisplay from "@/components/common/StatusDisplay";
import OrderItemDefinition from "@/components/common/OrderItemDefinition";
import DiagnosticWindow from "@/components/DiagnosticWindow";
import ChooseOperations from "@/components/common/ChooseOperations";

export default {
  name: "BookingWindow",
  components: {
    ChooseOperations,
    DiagnosticWindow,
    OrderItemDefinition,
    StatusDisplay,
  },
  props: {
    isVisible: Boolean,
  },
  data: () => ({
    currentStep: 1,
    newBooking: {
      shop: null,
      car: null,
      operations: null,
      comment: null,
      desiredDate: null,
    },
  }),
  methods: {
    book() {
      this.$store.dispatch("booking/book", this.newBooking);
    },
    chooseOperations(data) {
      this.newBooking.operations = data.operations;
      this.newBooking.comment = data.comment;
    },
  },
  computed: {
    ...mapFields("cars", ["cars", "isLoadedCars"]),
    ...mapFields("booking", ["isLoading", "isSuccessSent"]),
    lang() {
      return this.$i18n.locale;
    },
    timePickerProps() {
      return {
        format: this.lang === "ru" ? "24hr" : "ampm",
      };
    },
    datePickerProps() {
      return {
        locale: this.lang === "ru" ? "RU-ru" : "EN-en",
        min: this.today,
      };
    },
    today() {
      let today = new Date();
      let day =
        today.getDate() < 10 ? "0" + String(today.getDate()) : today.getDate();
      let month =
        (today.getMonth() + 1 < 10 ? 0 : "") + String(today.getMonth() + 1);
      return `${today.getFullYear()}-${month}-${day}`;
    },
    show: {
      get() {
        return this.isVisible;
      },
      set(isVisible) {
        if (!isVisible) {
          this.$emit("hide");
          this.isSuccessSent = false;
          this.currentStep = 1;
          this.newBooking = {
            shop: null,
            car: null,
            operations: null,
            comment: null,
            desiredDate: null,
          };
        }
      },
    },
    shops() {
      let shops = [];
      if (!this.cars || !this.cars.length) return [];
      this.cars.forEach((car) => {
        car.shops.forEach((shop) => {
          if (!shops.find((el) => el.shop_id === shop.shop_id)) {
            shops.push(shop);
          }
        });
      });
      return shops;
    },
  },
};
</script>

<style scoped></style>
