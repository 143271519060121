<template>
  <span class="text-no-wrap">
    <span v-if="type === 'part' || type === 'repairtime' && status">
      <span
        :style="{
          background: status && status.color,
        }"
        class="pa-1 d-inline-block mr-1 status-square"
      ></span>
      {{
        status && status.type === "system_order_part_status" ||
        status && status.type === "system_order_repairtime_status"
          ? $t("common.orderPartsSystemStatusNames." + status.name)
          : status.name
      }}
    </span>
    <span class="text-no-wrap pl-2" v-if="type === 'order'">
      <span
        :style="{
          background: status && status.color,
        }"
        class="pa-1 d-inline-block mr-1 status-square"
      ></span>
      {{
        status.system
          ? $t("common.orderSystemStatusNames." + status.name)
          : status.name
      }}
    </span>
  </span>
</template>

<script>
export default {
  name: "StatusDisplay",
  props: {
    status: Object,
    type: String,
  },
};
</script>

<style scoped>
.status-square {
  border-radius: 1px;
  width: 12px;
  height: 12px;
}
</style>
