import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/store.js";
import DatetimePicker from "vuetify-datetime-picker";
import vuetify from "./plugins/vuetify";
import { initI18n } from "./i18n";
import VuetifyDialog from "vuetify-dialog";
import "vuetify-dialog/dist/vuetify-dialog.css";
import "@fortawesome/fontawesome-free/css/all.css";
const moment = require("moment");
Vue.use(require("vue-moment"), {
  moment,
});
Vue.use(VuetifyDialog, {
  context: {
    vuetify,
  },
});

Vue.config.productionTip = false;
Vue.use(DatetimePicker);
initI18n(process.env.VUE_APP_API_URL).then((i18n) => {
  Vue.prototype.i18n = i18n;
  var vm = new Vue({
    i18n,
    router,
    store,
    vuetify,
    render: function (h) {
      return h(App);
    },
  }).$mount("#app");
});
