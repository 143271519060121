<template>
  <div class="text-no-wrap">
    <span class="wrap" v-if="num">
      <span class="part1" v-if="part1">{{ part1 }}</span
      ><span v-if="part2" class="part2">{{ part2 }}</span>
    </span>
  </div>
</template>

<script>
export default {
  name: "BeautyCarNumber",
  props: {
    number: {
      type: String,
    },
  },
  data: () => ({
    numTypes: [
      {
        type: "regular",
        regexp: /^[АВЕКМНОРСТУХ]\d{3}[АВЕКМНОРСТУХ]{2}\d{2,3}$/iu,
      },
    ],
  }),
  computed: {
    type() {
      for (let i in this.numTypes) {
        let num = this.num;
        num = num.split("A").join("А");
        num = num.split("B").join("В");
        num = num.split("E").join("Е");
        num = num.split("K").join("К");
        num = num.split("M").join("М");
        num = num.split("H").join("Н");
        num = num.split("O").join("О");
        num = num.split("P").join("Р");
        num = num.split("C").join("С");
        num = num.split("T").join("Т");
        num = num.split("Y").join("У");
        num = num.split("X").join("Х");
        if (this.numTypes[i].regexp.test(num)) {
          return this.numTypes[i].type;
        }
      }
      return null;
    },
    num() {
      let num = this.number.toUpperCase();
      return num;
    },
    part1() {
      switch (this.type) {
        case "regular":
          return this.num.slice(0, 6);
          break;
        default:
          return this.num;
          break;
      }
    },
    part2() {
      switch (this.type) {
        case "regular":
          return this.num.slice(6);
          break;
        default:
          return null;
          break;
      }
    },
  },
};
</script>

<style scoped>
.wrap {
  color: black;
  font-size: 14px;
  text-align: center;
  display: inline-block;
  background: white;
  border: 1px solid black;
  border-radius: 3px;
  font-weight: bold;
  width: 96px;
  display: inline-block;
  text-transform: lowercase;
}
.part1 {
  padding: 2px 6px 2px 6px;
  font-family: monospace;
}
.part2 {
  font-family: monospace;
  padding: 1px 3px 1px 3px;
  border-left: 1px solid black;
  width: 33px;
  display: inline-block;
  text-align: center;
}
.small .wrap {
  font-size: 11px;
  width: 77px;
}
.small .wrap .part1 {
  padding: 2px 3px 2px 3px;
}
.small .wrap .part2 {
  padding: 1px 2px 1px 2px;
  width: 27px;
}
</style>
