import API from "@/utils/api";
import { getField, updateField } from "vuex-map-fields";

export default {
  namespaced: true,
  state: {
    isLoadedCars: false,
    isLoadedOrders: false,
    cars: null,
    orders: [],
  },
  getters: {
    getField, //vuex-map-fields
  },
  mutations: {
    updateField, //vuex-map-fields
  },
  actions: {
    async get({ commit, state, dispatch }) {
      updateField(state, {
        path: "isLoadedCars",
        value: false,
      });
      return await API.get("/cars")
        .then((response) => {
          if (response && response.data && response.data.status === "error") {
            throw response.data.message || "Unknown error";
          }
          updateField(state, {
            path: "cars",
            value: response.data && response.data.cars,
          });
        })
        .catch((error) => {
          dispatch("common/showError", { error: error }, { root: true });
        })
        .finally(() => {
          updateField(state, {
            path: "isLoadedCars",
            value: true,
          });
        });
    },
    async ordersGet({ commit, state, dispatch }, payload) {
      updateField(state, {
        path: "orders",
        value: [],
      });
      updateField(state, {
        path: "isLoadedOrders",
        value: false,
      });
      return await API.get("/orders", {
        params: payload,
      })
        .then((response) => {
          if (response && response.data && response.data.status === "error") {
            throw response.data.message || "Unknown error";
          }
          updateField(state, {
            path: "orders",
            value: response && response.data,
          });
        })
        .catch((error) => {
          dispatch("common/showError", { error: error }, { root: true });
        })
        .finally(() => {
          updateField(state, {
            path: "isLoadedOrders",
            value: true,
          });
        });
    },
  },
};
