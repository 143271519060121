import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/Auth/auth";
import common from "./modules/Common/common";
import cars from "./modules/Cars/cars";
import operations from "./modules/Operations/operations";
import booking from "./modules/Booking/booking";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    common,
    cars,
    operations,
    booking,
  },
});
