import axios from "axios";

const cfg = {
    baseURL: process.env.VUE_APP_API_URL,
    responseType: "json",
    contentType: "application/json",
    withCredentials: true
};

const api = axios.create(cfg);
export default api;
