<template>
  <span class="text-caption">
    {{ $t("common.amount") }}: {{ item.amount }} {{ $t("common.price") }}:
    {{ item.price }}{{ currencySymbol ? currencySymbol : "" }}
    {{ $t("common.sum") }}: {{ parseFloat(item.price * item.amount).toFixed(2)
    }}{{ currencySymbol ? currencySymbol : "" }}
    <span v-if="item.interval && item.interval.start">
      <br />
      {{ $t("mygarage.planned_start_date") }}: {{ item.interval.start }}
    </span>
  </span>
</template>

<script>
export default {
  name: "OrderItemDefinition",
  props: {
    item: Object,
    currencySymbol: null,
  },
};
</script>

<style scoped></style>
