<template>
  <div>
    <v-menu open-on-hover top offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn small fab text v-bind="attrs" v-on="on">
          <img :src="selected && selected.image" />
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="(item, index) in sorted"
          :key="index"
          class="pointer"
          @click="changeTo(item)"
        >
          <v-list-item-avatar tile size="16"
            ><v-img :src="item.image"
          /></v-list-item-avatar>
          <v-list-item-action>{{ item.name }}</v-list-item-action>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "LanguageSwitcher",
  data: () => ({
    selected: null,
    items: [
      {
        name: "Русский",
        image: "https://levam.net/images/flags/ru.png",
        locale: "ru",
      },
      {
        name: "English",
        image: "https://levam.net/images/flags/en.png",
        locale: "en",
      },
      {
        name: "Española",
        image: "https://levam.net/images/flags/es.png",
        locale: "es",
      },
      {
        name: "Polski",
        image: "https://levam.net/images/flags/pl.png",
        locale: "pl",
      },
      {
        name: "Deutsch",
        image: "https://levam.net/images/flags/de.png",
        locale: "de",
      },
      {
        name: "Française",
        image: "https://levam.net/images/flags/fr.png",
        locale: "fr",
      },
    ],
  }),
  mounted() {
    let currentFlag = this.items.find(
      (item) => item.locale === this.$i18n.locale
    );
    if (currentFlag) {
      this.selected = currentFlag;
    }
  },
  methods: {
    changeTo(to) {
      this.$i18n.locale = to.locale;
      this.selected = to;
    },
  },
  computed: {
    sorted() {
      return this.items.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    },
  },
};
</script>

<style scoped></style>
