var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition","scrollable":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-toolbar',{staticClass:"flex-grow-0",attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.shop && _vm.shop.legal_name)+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.show = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"pt-3"},[(!_vm.isLoadedOrders)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),(_vm.orders.length === 0 && _vm.isLoadedOrders)?_c('v-alert',{attrs:{"border":"top","colored-border":"","type":"info","elevation":"1"}},[_vm._v(" "+_vm._s(_vm.$t("orders.no_orders_for_this_car"))+" ")]):_vm._e(),_c('v-expansion-panels',_vm._l((_vm.orders),function(order,i){return _c('v-expansion-panel',{key:i,staticClass:"d-inline-block"},[_c('v-expansion-panel-header',[_c('p',{staticClass:"d-inline-block ma-0"},[_vm._v(" "+_vm._s(_vm.$t("common.order"))+" #"+_vm._s(order.human_number)+" "+_vm._s(_vm.$t("orders.from"))+" "+_vm._s(_vm._f("moment")(order.created_at,"DD.MM.YYYY HH:mm"))+"; "+_vm._s(_vm.$t("orders.total_sum"))+": "),_c('strong',[_vm._v(_vm._s(order.total_sum))]),_vm._v(" "+_vm._s(order.shop && order.shop.currency && order.shop.currency.symbol)+" "),_c('status-display',{attrs:{"status":order.status,"type":"order"}})],1)]),_c('v-expansion-panel-content',[_vm._l((order.items.filter(
                function (el) { return el.item_type == 'repairtime'; }
              )),function(repairtime){return _c('v-card',{staticClass:"pa-2 mb-1",attrs:{"title":repairtime.name}},[(repairtime.idx === 'spec_diag')?_c('v-btn',{attrs:{"fab":"","elevation":"0","x-small":""},on:{"click":function($event){_vm.diagnosticWindow.isVisible = true;
                  _vm.diagnosticWindow.hasBrandOrPrice =
                    repairtime.hasBrandOrPrice;
                  _vm.diagnosticWindow.totalSum = repairtime.totalSum;
                  _vm.diagnosticWindow.diagnosticData =
                    repairtime.diagnostic_items || null;}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-eye")])],1):_vm._e(),_c('strong',[_vm._v(" "+_vm._s(repairtime.name)+" ")]),_c('status-display',{attrs:{"status":repairtime.status,"type":"repairtime"}}),_c('div',{staticClass:"pb-2"},[_c('order-item-definition',{attrs:{"item":repairtime,"currency-symbol":order.shop &&
                    order.shop.currency &&
                    order.shop.currency.symbol}})],1),(
                  order.items &&
                  order.items.filter(
                    function (el) { return el.repairtime_id_in_order == repairtime.id; }
                  ).length
                )?_c('h4',[_vm._v(" "+_vm._s(_vm.$t("orders.parts_for_repairtime"))+": ")]):_vm._e(),_vm._l((order.items.filter(
                  function (el) { return el.repairtime_id_in_order == repairtime.id; }
                )),function(part){return _c('div',{staticClass:"pl-2"},[_c('strong',[_vm._v(" "+_vm._s(part.brand)+" "+_vm._s(part.part_number)+" "+_vm._s(part.name)+" ")]),_c('status-display',{attrs:{"status":part.status,"type":"part"}}),_c('div',{staticClass:"pb-2"},[_c('order-item-definition',{attrs:{"item":part,"currency-symbol":order.shop &&
                      order.shop.currency &&
                      order.shop.currency.symbol}})],1)],1)})],2)}),(
                order.items &&
                order.items.filter(
                  function (el) { return el.item_type == 'part' && !el.repairtime_id_in_order; }
                ).length
              )?_c('h4',[_vm._v(" "+_vm._s(_vm.$t("componentMultiCart.parts_without_repairtimes"))+": ")]):_vm._e(),_vm._l((order.items.filter(
                function (el) { return el.item_type == 'part' && !el.repairtime_id_in_order; }
              )),function(part){return _c('v-card',{key:part.id,staticClass:"pa-2 mb-1"},[_c('div',[_c('strong',[_vm._v(_vm._s(part.brand)+_vm._s(part.part_number)+" "+_vm._s(part.name))]),_c('status-display',{attrs:{"status":part.status,"type":"part"}}),_c('div',{staticClass:"pb-2"},[_c('order-item-definition',{attrs:{"item":part,"currency-symbol":order.shop &&
                      order.shop.currency &&
                      order.shop.currency.symbol}})],1)],1)])})],2)],1)}),1)],1)],1),_c('diagnostic-window',{attrs:{"is-visible":_vm.diagnosticWindow.isVisible,"diagnostic-data":_vm.diagnosticWindow.diagnosticData,"has-brand-or-price":_vm.diagnosticWindow.hasBrandOrPrice,"total-sum":_vm.diagnosticWindow.totalSum},on:{"hide":function($event){_vm.diagnosticWindow.isVisible = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }