<template>
  <div class="mb-2">
    <v-autocomplete
      class="mt-3"
      outlined
      dense
      v-model="model"
      :items="items"
      :loading="isLoading"
      :search-input.sync="search"
      hide-details
      hide-no-data
      hide-selected
      item-text="title"
      item-value="title"
      :label="$t('booking.operations')"
      :placeholder="$t('booking.start_typing_for_search')"
      return-object
    ></v-autocomplete>
    <v-expand-transition>
      <v-list v-if="operations.length">
        <v-chip
          v-for="(field, i) in operations"
          :key="i"
          class="mb-2 mr-1"
          close
          @click:close="removeOperation(field)"
        >
          {{ field.title }}
        </v-chip>
      </v-list>
    </v-expand-transition>
    <v-text-field
      class="mt-2"
      v-model="comment"
      outlined
      hide-details
      :label="$t('booking.additional_information_that_may_be_important')"
    ></v-text-field>
  </div>
</template>

<script>
import { mapFields } from "vuex-map-fields";

export default {
  name: "ChooseOperations",
  props: ["shop_id"],
  data: () => ({
    descriptionLimit: 60,
    model: null,
    search: null,
    comment: null,
    findTimeoutId: null,
    operations: [],
  }),
  methods: {
    emitChangeOperations() {
      this.$emit("changeOperations", {
        operations: this.operations,
        comment: this.comment,
      });
    },
    removeOperation(item) {
      this.operations = this.operations.filter((el) => el !== item);
      this.$emit("changeOperations", {
        operations: this.operations,
        comment: this.comment,
      });
    },
  },
  computed: {
    ...mapFields("operations", ["entries", "isLoading"]),
    items() {
      let result = [];
      for (let i in this.entries) {
        let item = JSON.parse(JSON.stringify(this.entries[i]));
        if (item.id === null) {
          item.title = item.text;
        } else {
          let json = JSON.parse(item.text);
          item.title = json[this.lang];
        }
        result.push(item);
      }
      return result;
    },
    lang() {
      return this.$i18n.locale;
      //return window.levam_platform_config.lang || 'ru';
    },
    operationAutocompleteName() {
      switch (this.lang) {
        case "ru":
          return "name_ru";
        default:
          return "name_en";
      }
    },
  },

  watch: {
    model(operation) {
      if (operation) this.operations.push(operation);
      this.search = null;
      this.entries = [];
      this.model = null;
      this.emitChangeOperations();
    },
    comment() {
      this.emitChangeOperations();
    },
    async search(val) {
      clearTimeout(this.findTimeoutId);
      if (!val) return;
      if (this.isLoading) return;
      this.findTimeoutId = setTimeout(() => {
        this.$store.dispatch("operations/get", {
          search: this.search,
          shop_id: this.shop_id,
        });
      }, 1000);
    },
  },
};
</script>

<style scoped></style>
