import API from "@/utils/api";
import { getField, updateField } from "vuex-map-fields";

export default {
  namespaced: true,
  state: {
    isLoading: false,
    entries: [],
  },
  getters: {
    getField, //vuex-map-fields
  },
  mutations: {
    updateField, //vuex-map-fields
  },
  actions: {
    async get({ commit, state, dispatch }, payload) {
      updateField(state, {
        path: "isLoading",
        value: true,
      });
      return await API.get(
        `/operations?search=${payload.search}&shop_id=${payload.shop_id}`
      )
        .then((response) => {
          if (response && response.data && response.data.status === "error") {
            throw response.data.message || "Unknown error";
          }
          updateField(state, {
            path: "entries",
            value: response && response.data && response.data.data,
          });
        })
        .catch((error) => {
          dispatch("common/showError", { error: error }, { root: true });
        })
        .finally(() => {
          updateField(state, {
            path: "isLoading",
            value: false,
          });
        });
    },
  },
};
