import API from "@/utils/api";
import { getField, updateField } from "vuex-map-fields";

export default {
  namespaced: true,
  state: {
    authenticated: false,
    isLoading: false,
    phone: null,
    password: null,
    passwordIsSending: false,
  },
  getters: {
    getField, //vuex-map-fields
    authenticated(state) {
      return state.authenticated;
    },
  },
  mutations: {
    updateField, //vuex-map-fields
    setAuthenticated: (state, payload) => {
      state.authenticated = payload;
      state.password = null;
    },
  },
  actions: {
    async sendPassword({ commit, state, dispatch }, payload) {
      updateField(state, { path: "passwordIsSending", value: true });
      return await API.put("/send-password", { phone: state.phone })
        .then((response) => {
          if (response && response.data && response.data.status === "success") {
            return true;
          } else {
            throw (
              (response.data && response.data.error) ||
              (response.data && response.data.message) ||
              "Ошибка при создании пароля"
            );
          }
        })
        .catch((error) => {
          dispatch("common/showError", { error: error }, { root: true });
        })
        .finally(() => {
          updateField(state, { path: "passwordIsSending", value: false });
        });
    },
    async logout({ commit, state, dispatch }) {
      return await API.get("/logout")
        .then((response) => {
          commit(
            "setAuthenticated",
            response.data && response.data.authenticated
          );
        })
        .catch((error) => {
          dispatch("common/showError", { error: error }, { root: true });
        });
    },
    async check({ commit, state, dispatch }) {
      return await API.get("/authenticated")
        .then((response) => {
          commit(
            "setAuthenticated",
            response.data && response.data.authenticated
          );
          return (
            response.data &&
            response.data.authenticated &&
            response.data.authenticated === true
          );
        })
        .catch((error) => {
          dispatch("common/showError", { error: error }, { root: true });
        });
    },
    async login({ commit, state, dispatch }) {
      updateField(state, { path: "isLoading", value: true });
      return await API.post("/login", {
        phone: state.phone,
        password: state.password,
      })
        .then((response) => {
          updateField(state, { path: "isLoading", value: false });
          commit("setAuthenticated", response.data.status === "success");
        })
        .catch((error) => {
          updateField(state, { path: "isLoading", value: false });
          commit("setAuthenticated", false);
          dispatch("common/showError", { error: error }, { root: true });
        });
    },
  },
};
