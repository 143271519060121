<template>
  <v-app>
    <v-app-bar app color="teal darken-2" dark v-if="authenticated">
      <div class="d-flex align-center">
        <img :src="require(`@/assets/logo.png`)" height="40px" width="51px" />
      </div>
      <v-spacer></v-spacer>
      <language-switcher/>
      <v-btn icon @click="logout" :title="$t('common.logout')">
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import LanguageSwitcher from "@/components/common/LanguageSwitcher";

export default {
  name: "App",
  components: {LanguageSwitcher},
  data: () => ({
    //
  }),
  computed: {
    ...mapFields("auth", ["authenticated"]),
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout").then(() => {
        if (this.authenticated !== true) {
          this.$router.push({ name: "Auth" });
        }
      });
    },
  },
};
</script>
