var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition","scrollable":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-toolbar',{staticClass:"flex-grow-0",attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t("common.book_now")))]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.show = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"pt-3"},[_c('v-container',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[(!_vm.isSuccessSent)?_c('v-stepper',{attrs:{"vertical":""},model:{value:(_vm.currentStep),callback:function ($$v) {_vm.currentStep=$$v},expression:"currentStep"}},[_c('v-stepper-step',{attrs:{"complete":_vm.currentStep > 1,"step":"1"}},[_vm._v(" "+_vm._s(_vm.$t("booking.choosing"))+" "),(
                    _vm.newBooking.shop &&
                    _vm.newBooking.shop.shop_id &&
                    _vm.currentStep > 1
                  )?_c('small',[_vm._v(_vm._s(_vm.newBooking.shop.legal_name)+" "+_vm._s(_vm.newBooking.shop.phone !== "" && _vm.newBooking.shop.phone ? ("(" + (_vm.newBooking.shop.phone) + ")") : ""))]):_vm._e()]),_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-list',[_c('v-list-item-group',{attrs:{"color":"primary"},on:{"change":function (shop) { return (_vm.newBooking.shop = shop); }}},_vm._l((_vm.shops),function(item){return _c('v-list-item',{key:item.shop_id,attrs:{"value":item}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-car-wrench")])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.legal_name)}})],1)],1)}),1)],1),_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.newBooking.shop || !_vm.newBooking.shop.shop_id},on:{"click":function($event){_vm.currentStep = 2}}},[_vm._v(" "+_vm._s(_vm.$t("common.continue"))+" ")])],1),_c('v-stepper-step',{attrs:{"complete":_vm.currentStep > 2,"step":"2"}},[_vm._v(" "+_vm._s(_vm.$t("booking.choosing_car"))+" "),(
                    _vm.newBooking.car && _vm.newBooking.car.id && _vm.currentStep > 2
                  )?_c('small',[_vm._v(" "+_vm._s(_vm.newBooking.car.brand)+" "+_vm._s(_vm.newBooking.car.family)+" "+_vm._s(_vm.newBooking.car.model)+" ")]):_vm._e()]),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('v-list',[_c('v-list-item-group',{attrs:{"color":"primary"},on:{"change":function (car) { return (_vm.newBooking.car = car.car); }}},_vm._l((_vm.cars),function(item){return _c('v-list-item',{key:item.shop_id,attrs:{"value":item}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.car.brand)+" "+_vm._s(item.car.family)+" "+_vm._s(item.car.model)+" ")])],1)],1)}),1)],1),_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.newBooking.car || !_vm.newBooking.car.id},on:{"click":function($event){_vm.currentStep = 3}}},[_vm._v(" "+_vm._s(_vm.$t("common.continue"))+" ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.currentStep = 1}}},[_vm._v(" "+_vm._s(_vm.$t("common.cancel"))+" ")])],1),_c('v-stepper-step',{attrs:{"complete":_vm.currentStep > 3,"step":"3"}},[_vm._v(" "+_vm._s(_vm.$t("booking.choosing_repairtimes"))+" ")]),_c('v-stepper-content',{attrs:{"step":"3"}},[_c('v-card',{attrs:{"elevation":"0"}},[_c('choose-operations',{attrs:{"shop_id":_vm.newBooking.shop && _vm.newBooking.shop.shop_id},on:{"changeOperations":_vm.chooseOperations}})],1),_c('v-btn',{attrs:{"color":"primary","disabled":(_vm.newBooking.comment === null ||
                      _vm.newBooking.comment === '') &&
                    (_vm.newBooking.operations === null ||
                      _vm.newBooking.operations.length === 0)},on:{"click":function($event){_vm.currentStep = 4}}},[_vm._v(" "+_vm._s(_vm.$t("common.continue"))+" ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.currentStep = 2}}},[_vm._v(" "+_vm._s(_vm.$t("common.previous"))+" ")])],1),_c('v-stepper-step',{attrs:{"complete":_vm.currentStep > 4,"step":"4"}},[_vm._v(" "+_vm._s(_vm.$t("booking.choosing_date"))+" "),(_vm.newBooking.desiredDate)?_c('small',[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.newBooking.desiredDate,"DD.MM.YYYY HH:mm"))+" ")]):_vm._e()]),_c('v-stepper-content',{attrs:{"step":"4"}},[_c('v-card',{staticClass:"mb-3 pt-1",attrs:{"elevation":"0"}},[_c('v-datetime-picker',{attrs:{"textFieldProps":{
                      outlined: true,
                      dense: true,
                      'hide-details': true,
                    },"label":_vm.$t('booking.select_your_desired_date_and_time'),"dateFormat":"dd.MM.yyyy","timeFormat":"HH:mm","timePickerProps":_vm.timePickerProps,"datePickerProps":_vm.datePickerProps,"locale":"RU-ru"},model:{value:(_vm.newBooking.desiredDate),callback:function ($$v) {_vm.$set(_vm.newBooking, "desiredDate", $$v)},expression:"newBooking.desiredDate"}},[_c('template',{slot:"dateIcon"},[_c('v-icon',[_vm._v("mdi-calendar")])],1),_c('template',{slot:"timeIcon"},[_c('v-icon',[_vm._v("mdi-clock")])],1)],2)],1),_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.newBooking.desiredDate},on:{"click":function($event){_vm.currentStep = 5}}},[_vm._v(" "+_vm._s(_vm.$t("common.continue"))+" ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.currentStep = 3}}},[_vm._v(_vm._s(_vm.$t("common.previous"))+" ")])],1),_c('v-stepper-step',{attrs:{"step":"5"}},[_vm._v(" "+_vm._s(_vm.$t("booking.confirmation"))+" ")]),_c('v-stepper-content',{attrs:{"step":"5"}},[_c('v-card',{staticClass:"mb-3 pt-1",attrs:{"elevation":"0"}},[_c('h3',{staticClass:"mb-0 pb-0"},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.newBooking.desiredDate,"DD.MM.YYYY HH:mm"))+" ")]),_c('small',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.$t("booking.date_hint"))+" ")]),_c('br'),_c('br'),_c('h3',[_vm._v(_vm._s(_vm.newBooking.shop && _vm.newBooking.shop.legal_name))]),_c('strong',[_vm._v(_vm._s(_vm.newBooking.shop && _vm.newBooking.shop.fact_address))]),(
                      _vm.newBooking.shop &&
                      _vm.newBooking.shop.phone &&
                      _vm.newBooking.shop.phone !== ''
                    )?_c('strong',[_c('br'),_vm._v(_vm._s(_vm.newBooking.shop && _vm.newBooking.shop.phone))]):_vm._e(),_c('br'),_c('br'),_c('h3',[_c('strong',[_vm._v(_vm._s(_vm.newBooking && _vm.newBooking.car && _vm.newBooking.car.brand))]),_c('strong',[_vm._v(_vm._s(_vm.newBooking && _vm.newBooking.car && _vm.newBooking.car.family))]),_c('strong',[_vm._v(_vm._s(_vm.newBooking && _vm.newBooking.car && _vm.newBooking.car.model))])]),_c('br')]),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.isLoading,"loading":_vm.isLoading},on:{"click":_vm.book}},[_vm._v(" "+_vm._s(_vm.$t("booking.book"))+" ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.currentStep = 4}}},[_vm._v(_vm._s(_vm.$t("common.previous"))+" ")])],1)],1):_vm._e(),(_vm.isSuccessSent)?_c('v-alert',{attrs:{"outlined":"","color":"success"}},[_vm._v(" "+_vm._s(_vm.$t("booking.success_sent"))+" ")]):_vm._e()],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }