import API from "@/utils/api";
import { getField, updateField } from "vuex-map-fields";

export default {
  namespaced: true,
  state: {
    isLoading: false,
    isSuccessSent: false,
  },
  getters: {
    getField, //vuex-map-fields
  },
  mutations: {
    updateField, //vuex-map-fields
  },
  actions: {
    async book({ commit, state, dispatch }, payload) {
      updateField(state, {
        path: "isLoading",
        value: true,
      });
      return await API.post(`/booking`, payload)
        .then((response) => {
          if (response.data && response.data.status === "success") {
            updateField(state, {
              path: "isSuccessSent",
              value: true,
            });
          } else {
            if (response.data) {
              throw (
                (response.data && response.data.error) ||
                (response.data && response.data.message) ||
                "Unknown error"
              );
            }
          }
          return response;
        })
        .catch((error) => {
          dispatch("common/showError", { error: error }, { root: true });
        })
        .finally(() => {
          updateField(state, {
            path: "isLoading",
            value: false,
          });
        });
    },
  },
};
