<template>
  <v-dialog
    v-model="show"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    scrollable
  >
    <v-card>
      <v-toolbar dark color="primary" class="flex-grow-0">
        <v-toolbar-title> </v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn icon dark @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-3">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th>{{ $t("common.title") }}</th>
                <th>
                  {{ $t("common.status") }}
                </th>
                <th>
                  {{ $t("common.replacement") }}
                </th>
                <th>
                  {{ $t("common.regulation") }}
                </th>
                <th>{{ $t("common.comment") }}</th>

                <th v-if="hasBrandOrPrice">{{ $t("common.brand") }}</th>
                <th v-if="hasBrandOrPrice">{{ $t("common.price") }}</th>
              </tr>
            </thead>

            <tr v-for="item in diagnosticData" class="mb-5">
              <td class="text-left pb-2">
                {{ item['name'] }}
              </td>
              <td class="text-center pb-2" align="center" valign="middle">
                <v-icon v-html="item['status_icon']"></v-icon>
              </td>
              <td class="text-center pb-2" align="center">
                <v-icon v-if="item['replacement']">fa-check</v-icon>
                <!--                @if($item['replacement'])-->
                <!--                <i-->
                <!--                    class="fas fa-check"-->
                <!--                    style="display: block; margin: 0 auto"-->
                <!--                ></i>-->
                <!--                @endif-->
              </td>
              <td class="text-center pb-2">
                {{ item["regulation"] }}
              </td>
              <td
                  class="text-right pb-2"
              >
                {{ item["comment"] }}
              </td>

              <td class="text-right pb-2" v-if="hasBrandOrPrice">
                {{ item["brand"] }}
              </td>
              <td class="text-right pb-2" v-if="hasBrandOrPrice">
                {{ item["price"] }}
              </td>


            </tr>


            <tr v-if="hasBrandOrPrice">
              <td colspan="5" class="text-right pb-2">
                <strong>{{ $t("common.total_sum") }}:</strong>
              </td>
              <td colspan="2" class="text-right pb-2">
                <strong>
                  {{totalSum}}
                </strong>
              </td>
            </tr>

          </template>
        </v-simple-table>
      </v-card-text>
<!--      <v-card-text class="pt-3">-->
<!--        <pre>111{{ diagnosticData }}</pre>-->
<!--      </v-card-text>-->
    </v-card>
  </v-dialog>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import StatusDisplay from "@/components/common/StatusDisplay";
import OrderItemDefinition from "@/components/common/OrderItemDefinition";

export default {
  name: "DiagnosticWindow",
  components: { OrderItemDefinition, StatusDisplay },
  props: ["isVisible", "diagnosticData", "hasBrandOrPrice", "totalSum"],
  data: () => ({}),
  watch: {
    isVisible(val) {},
  },
  methods: {},
  computed: {
    show: {
      get() {
        return this.isVisible;
      },
      set(val) {
        if (!val) this.$emit("hide");
      },
    },

  },
};
</script>

<style scoped></style>
