<template>
  <v-app id="inspire">
    <v-main>
      <v-container v-if="!authenticated" class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4">
            <v-card class="elevation-1">
              <v-toolbar :color="$vuetify.theme.dark ? 'light' : 'white'" flat>
                <v-toolbar-title> {{ $t("form.login.title") }}</v-toolbar-title>
                <div class="flex-grow-1"></div>
                <img
                  :src="require(`@/assets/logo.png`)"
                  height="40px"
                  width="51px"
                />
              </v-toolbar>
              <v-card-text>
                <v-form ref="authForm">
                  <v-text-field
                    outlined
                    :disabled="isLoading"
                    autocomplete="phone"
                    v-model="phone"
                    :label="$t('common.phone')"
                    id="phone"
                    prefix="+"
                    placeholder="79999999999"
                    validate-on-blur
                    :rules="[
                      (v) =>
                        !!v || $t('form.validationErrors.field_is_required'),
                      (v) =>
                        (v && v.length === 11) ||
                        $t('form.validationErrors.minLength', [11]),
                    ]"
                    append-icon="fa-phone"
                    required
                    @keyup.enter="submit"
                  ></v-text-field>

                  <v-text-field
                    ref="password"
                    :disabled="isLoading"
                    outlined
                    v-model="password"
                    validate-on-blur
                    :label="$t('form.login.sms_code')"
                    required
                    append-icon="fa-key"
                    id="sms_key"
                    hide-details
                    name="sms_key"
                    inputmode="numeric"
                    pattern="[0-9]*"
                    @keyup.enter="submit"
                  ></v-text-field>
                  <v-checkbox v-model="checkbox">
                    <template v-slot:label>
                      <div>
                        {{ $t("form.login.agree_with") }}
                        <a
                          target="_blank"
                          href="https://mygarage.pro/agreements.pdf"
                          @click.stop
                          >{{ $t("form.login.terms_of_use") }}</a
                        >
                        {{
                          $t(
                            "form.login.service_and_i_agree_to_the_processing_of_personal_data"
                          )
                        }}
                      </div>
                    </template>
                  </v-checkbox>
                </v-form>
                <v-divider class=""></v-divider>
              </v-card-text>
              <v-card-actions>
                <language-switcher class="mb-1" />
                <div class="flex-grow-1"></div>
                <v-btn
                  :disabled="!checkbox || passwordIsSending || isLoading"
                  :loading="passwordIsSending || isLoading || passwordIsSending"
                  elevation="0"
                  class="mr-2 mb-2"
                  min-width="100"
                  color="primary"
                  @click="sendPassword"
                  @keyup.enter="sendPassword"
                >
                  {{ $t("form.login.get_password_in_sms") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import LanguageSwitcher from "../components/common/LanguageSwitcher";
export default {
  name: "Auth",
  components: { LanguageSwitcher },
  data: () => ({
    isVisibleAgreementsWin: false,
    checkbox: false,
  }),
  computed: {
    ...mapFields("auth", [
      "phone",
      "password",
      "authenticated",
      "isLoading",
      "passwordIsSending",
    ]),
  },
  watch: {
    password(value) {
      if (value && value.length === 4) this.submit();
    },
  },
  methods: {
    sendPassword() {
      if (!this.$refs.authForm.validate()) return;
      this.$store.dispatch("auth/sendPassword").then((res) => {
        if (res) {
          this.isVisibleAgreementsWin = false;
          this.$dialog.notify.info(this.$t("form.login.password_is_sended"), {
            position: "bottom-right",
            timeout: 5000,
          });
        }
      });
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.password.$refs.input.focus();
        });
      });
    },
    showAgreementsWin() {
      if (!this.$refs.authForm.validate()) return;
      this.isVisibleAgreementsWin = true;
    },
    submit() {
      if (!this.$refs.authForm.validate() || !this.password) return;
      this.$store.dispatch("auth/login").then(() => {
        if (this.authenticated) {
          this.$router.push({ name: "Home" });
        }
      });
    },
  },
};
</script>
