<template>
  <v-dialog
    v-model="show"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    scrollable
  >
    <v-card>
      <v-toolbar dark color="primary" class="flex-grow-0">
        <v-toolbar-title>{{ shop && shop.legal_name }} </v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn icon dark @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-3">
        <v-progress-circular
          indeterminate
          color="primary"
          v-if="!isLoadedOrders"
        ></v-progress-circular>
        <v-alert
          border="top"
          colored-border
          type="info"
          elevation="1"
          v-if="orders.length === 0 && isLoadedOrders"
        >
          {{ $t("orders.no_orders_for_this_car") }}
        </v-alert>
        <v-expansion-panels>
          <v-expansion-panel
            v-for="(order, i) in orders"
            :key="i"
            class="d-inline-block"
          >
            <v-expansion-panel-header>
              <p class="d-inline-block ma-0">
                {{ $t("common.order") }} #{{ order.human_number }}
                {{ $t("orders.from") }}
                {{ order.created_at | moment("DD.MM.YYYY HH:mm") }};
                {{ $t("orders.total_sum") }}:
                <strong>{{ order.total_sum }}</strong>
                {{
                  order.shop &&
                  order.shop.currency &&
                  order.shop.currency.symbol
                }}
                <status-display
                  :status="order.status"
                  type="order"
                ></status-display>
              </p>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card
                v-for="repairtime in order.items.filter(
                  (el) => el.item_type == 'repairtime'
                )"
                class="pa-2 mb-1"
                :title="repairtime.name"
              >
                <v-btn
                  v-if="repairtime.idx === 'spec_diag'"
                  fab
                  elevation="0"
                  x-small
                  @click="
                    diagnosticWindow.isVisible = true;
                    diagnosticWindow.hasBrandOrPrice =
                      repairtime.hasBrandOrPrice;
                    diagnosticWindow.totalSum = repairtime.totalSum;
                    diagnosticWindow.diagnosticData =
                      repairtime.diagnostic_items || null;
                  "
                  ><v-icon small>mdi-eye</v-icon></v-btn
                >
                <strong>
                  {{ repairtime.name }}
                </strong>
                <status-display
                  :status="repairtime.status"
                  type="repairtime"
                ></status-display>
                <div class="pb-2">
                  <order-item-definition
                    :item="repairtime"
                    :currency-symbol="
                      order.shop &&
                      order.shop.currency &&
                      order.shop.currency.symbol
                    "
                  />
                </div>
                <h4
                  v-if="
                    order.items &&
                    order.items.filter(
                      (el) => el.repairtime_id_in_order == repairtime.id
                    ).length
                  "
                >
                  {{ $t("orders.parts_for_repairtime") }}:
                </h4>
                <div
                  class="pl-2"
                  v-for="part in order.items.filter(
                    (el) => el.repairtime_id_in_order == repairtime.id
                  )"
                >
                  <strong>
                    {{ part.brand }}
                    {{ part.part_number }} {{ part.name }}
                  </strong>
                  <status-display
                    :status="part.status"
                    type="part"
                  ></status-display>
                  <div class="pb-2">
                    <order-item-definition
                      :item="part"
                      :currency-symbol="
                        order.shop &&
                        order.shop.currency &&
                        order.shop.currency.symbol
                      "
                    />
                  </div>
                </div>
              </v-card>
              <h4
                v-if="
                  order.items &&
                  order.items.filter(
                    (el) => el.item_type == 'part' && !el.repairtime_id_in_order
                  ).length
                "
              >
                {{ $t("componentMultiCart.parts_without_repairtimes") }}:
              </h4>
              <v-card
                v-for="part in order.items.filter(
                  (el) => el.item_type == 'part' && !el.repairtime_id_in_order
                )"
                class="pa-2 mb-1"
                :key="part.id"
              >
                <div>
                  <strong
                    >{{ part.brand }}{{ part.part_number }}
                    {{ part.name }}</strong
                  >
                  <status-display
                    :status="part.status"
                    type="part"
                  ></status-display>
                  <div class="pb-2">
                    <order-item-definition
                      :item="part"
                      :currency-symbol="
                        order.shop &&
                        order.shop.currency &&
                        order.shop.currency.symbol
                      "
                    />
                  </div>
                </div>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>
    <diagnostic-window
      :is-visible="diagnosticWindow.isVisible"
      :diagnostic-data="diagnosticWindow.diagnosticData"
      :has-brand-or-price="diagnosticWindow.hasBrandOrPrice"
      :total-sum="diagnosticWindow.totalSum"
      @hide="diagnosticWindow.isVisible = false"
    />
  </v-dialog>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import StatusDisplay from "@/components/common/StatusDisplay";
import OrderItemDefinition from "@/components/common/OrderItemDefinition";
import DiagnosticWindow from "@/components/DiagnosticWindow";

export default {
  name: "OrderWindow",
  components: { DiagnosticWindow, OrderItemDefinition, StatusDisplay },
  props: {
    isVisible: Boolean,
    car: Object,
    shop: Object,
  },
  data: () => ({
    diagnosticWindow: {
      isVisible: false,
      diagnosticData: [],
      hasBrandOrPrice: false,
      totalSum: 0,
    },
  }),
  watch: {
    isVisible(val) {
      if (val) {
        this.getOrders();
      }
    },
  },
  methods: {
    getOrders() {
      this.$store.dispatch("cars/ordersGet", {
        shop_id: this.shop.shop_id,
        car_brand: this.car.car.brand,
        car_vin: this.car.car.vin,
        car_number: this.car.car.car_number,
      });
    },
  },
  computed: {
    transformedOrders() {},
    ...mapFields("cars", ["orders", "isLoadedOrders"]),
    show: {
      get() {
        return this.isVisible;
      },
      set(val) {
        if (!val) this.$emit("hide");
      },
    },
  },
};
</script>

<style scoped></style>
