import Vue from "vue";
import VueI18n from "vue-i18n";
import API from "@/utils/api";

Vue.use(VueI18n);

const language = (navigator.language || "en").toLowerCase();

const languageGroups = {
  ru: [
    "ru",
    "ru_by",
    "ru_kg",
    "ru_kz",
    "ru_md",
    "ru_ru",
    "ru_ua",
    "be",
    "be_by",
    "uz",
    "uz_arab",
    "uz_arab_af",
    "uz_cyrl",
    "uz_cyrl_uz",
    "uz_latn",
    "uz_latn_uz",
    "ky",
    "ky_kg",
    "kk",
    "kk_kz",
    "ru-by",
    "ru-kg",
    "ru-kz",
    "ru-md",
    "ru-ru",
    "ru-ua",
    "be-by",
    "uz-arab",
    "uz-arab-af",
    "uz-cyrl",
    "uz-cyrl-uz",
    "uz-latn",
    "uz-latn-uz",
    "ky-kg",
    "kk-kz",
    "tg-tj",
    "uk-ua",
    "tg",
    "tg_tj",
    "uk",
    "uk_ua",
  ],
  es: [
    "es_ar",
    "es_bo",
    "es_cl",
    "es_co",
    "es_cr",
    "es_cu",
    "es_do",
    "es_ec",
    "es_es",
    "es_es@euro",
    "es_gt",
    "es_hn",
    "es_mx",
    "es_ni",
    "es_pa",
    "es_pe",
    "es_pr",
    "es_py",
    "es_sv",
    "es_us",
    "es_uy",
    "es_ve",
    "es",
  ],
  de: ["de-at", "de-ch", "de-de", "de-li", "de-lu", "de"],
  fr: ["fr-be", "fr-ca", "fr-ch", "fr-fr", "fr-lu", "fr-mc", "fr"],
  pl: ["pl-PL", "pl"],
};

const getLocale = (language) => {
  for (const locale in languageGroups) {
    if (languageGroups[locale].includes(language)) {
      return locale;
    }
  }
  return "en";
};

const locale = getLocale(language);
function loadLanguage(lang, apiURL) {
  return API.get("/i18n")
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      console.error(error);
    });
}

export function initI18n(apiURL) {
  return loadLanguage(locale, apiURL).then(function (data) {
    return new VueI18n({
      locale: locale,
      globalInjection: true,
      fallbackLocale: "en",
      messages: data,
    });
  });
}
