<template>
  <v-container>
    <v-row no-gutters>
      <v-col>
        <v-card class="pa-2" :loading="!isLoadedCars" elevation="0">
          <v-row class="pb-2">
            <v-col class="pr-0 mr-0 text-no-wrap"
              ><h2>{{ $t("common.my_cars") }}</h2></v-col
            >
            <v-col class="text-right pl-0 ml-0">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    <v-btn
                      v-if="isLoadedCars"
                      color="teal darken-2"
                      @click="bookingWindow.isVisible = true"
                    >
                      <v-icon class="white--text">mdi-car-wrench</v-icon>
                    </v-btn>
                  </span>
                </template>
                {{ $t("common.book_now") }}
              </v-tooltip>
            </v-col>
          </v-row>
          <v-expansion-panels>
            <v-expansion-panel v-for="(car, i) in cars" :key="i">
              <v-expansion-panel-header>
                {{ car.car.brand }}
                {{ car.car.family }}
                {{ car.car.model }}
                <template v-if="car.car.vin"
                  >, [{{ car.car.vin.toUpperCase() }}]
                </template>
                <template v-if="car.car.car_number">
                  <beauty-car-number
                    class="ml-2"
                    :number="car.car.car_number ? car.car.car_number : ''"
                  />
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card
                  v-for="shop in car.shops"
                  class="pa-2 mb-1"
                  :key="shop.id"
                >
                  <p>
                    <v-icon>mdi-car-wrench</v-icon>
                    {{ $t("common.service_station") }} "{{ shop.legal_name }}",
                    {{ shop.fact_address }}
                  </p>
                  <v-btn text @click="showOrders(shop, car)">
                    {{ $t("common.my_orders") }}
                  </v-btn>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-col>
    </v-row>
    <order-window
      :shop="orderWindow.shop"
      :car="orderWindow.car"
      :is-visible="orderWindow.isVisible"
      @hide="
        orderWindow.isVisible = false;
        orderWindow.car = null;
        orderWindow.shop = null;
      "
    />
    <booking-window
      :is-visible="bookingWindow.isVisible"
      @hide="bookingWindow.isVisible = false"
    />
  </v-container>
</template>
<script>
import { mapFields } from "vuex-map-fields";
import BeautyCarNumber from "@/components/common/BeautyCarNumber";
import OrderWindow from "@/components/OrderWindow";
import BookingWindow from "@/components/BookingWindow";
export default {
  name: "Home",
  components: { BookingWindow, OrderWindow, BeautyCarNumber },
  data: () => ({
    orderWindow: {
      shop: null,
      car: null,
      isVisible: false,
    },
    bookingWindow: {
      isVisible: false,
    },
  }),
  mounted() {
    this.$store.dispatch("cars/get");
  },
  computed: {
    ...mapFields("cars", ["cars", "isLoadedCars"]),
  },
  methods: {
    showOrders(shop, car) {
      this.orderWindow.isVisible = true;
      this.orderWindow.shop = shop;
      this.orderWindow.car = car;
    },
  },
};
</script>
