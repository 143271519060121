import store from "@/store/store.js";
import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import Auth from "./views/Auth.vue";

Vue.use(Router);
const router = new Router({
  base: "/",
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home,
    },
    {
      path: "/auth",
      name: "Auth",
      component: Auth,
      beforeEnter: (to, from, next) => {
        store.getters["auth/authenticated"] === true ? next("/") : next();
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.name != "Auth") {
    store
      .dispatch("auth/check")
      .then((isAuthorized) => {
        return isAuthorized ? next() : next("/auth");
      })
      .catch(() => {
        //console.log(e)
      });
  } else {
    next();
  }
});

export default router;
